import { observable, action, makeAutoObservable, runInAction } from 'mobx'
import { IToast } from '../types'
export class UiStore {

    lingua: string = ''

    //Header modal
    headerModal: boolean = false

    //Aside modal
    ConfermaChiusuraAsideModal: boolean = false
    asideModal: boolean = false
    bloccaUi: boolean = false
    asideModalComponent: JSX.Element | boolean = false
    asideModalComponentSuccessivo: JSX.Element | boolean = false

    //Big screen modal
    ConfermaChiusuraBigScreenModal: boolean = false
    bigScreenModal: boolean = false
    bigScreenModalComponent: JSX.Element | boolean = false

    //Big screen modal per i file di log
    ConfermaChiusuraLogScreenModal: boolean = false
    logScreenModal: boolean = false
    logScreenModalComponent: JSX.Element | boolean = false
    logScreenText: string = ''

    //Dialogue
    dialogue: boolean = false
    dialogueComponent: JSX.Element | boolean = false

    //Toast
    toast: IToast | null = null

    //Main nav mobile
    mainNavMobile: boolean = false

    //Main nav reduce
    mainNavFull: boolean = false

    //Search bar mobile
    searchBarMobile: boolean = false

    //Ricerca
    ricerca: string = ''
    ricercaUtenti: string = ''

    //FollowMouse
    followMouse: boolean = false
    followMouseComponent: JSX.Element | boolean = false

    //FollowMouse
    followMouseModal: boolean = false
    followMouseModalComponent: JSX.Element | boolean = false

    //Debug
    debug: boolean = false

    //Aggiornamento generico - per ascoltare azioni generiche nell'applicazione
    aggiornamentoGenerico: boolean = false

    constructor() {
        makeAutoObservable(this, {

            lingua: observable,
            cambiaLingua: action,

            //Header Modal
            headerModal: observable,
            toggleHeaderModal: action,

            //Aside Modal
            ConfermaChiusuraAsideModal: observable,
            asideModal: observable,
            bloccaUi: observable,
            mountAsideModal: action,
            unmountAsideModal: action,
            unmountConfermaChiusuraAsideModal: action,

            //Big screen modal
            ConfermaChiusuraBigScreenModal: observable,
            bigScreenModal: observable,
            mountBigScreenModal: action,
            unmountBigScreenModal: action,
            unmountConfermaChiusuraBigScreenModal: action,

            //Toast
            toast: observable,
            mountToast: action,

            //Main nav mobile
            mainNavMobile: observable,
            toggleMainNavMobile: action,

            //Main nav reduce
            mainNavFull: observable,
            toggleMainNavFull: action,

            //Search bar mobile
            searchBarMobile: observable,
            toggleSearchBarMobile: action,

            //Ricerca
            ricerca: observable,
            resettaRicerca: action,
            ricercaUtenti: observable,

            //Follow Mouse
            followMouse: observable,
            followMouseComponent: observable,
            mountFollowMouse: action,
            unmountFollowMouse: action,

            //Follow Mouse Modal
            followMouseModal: observable,
            followMouseModalComponent: observable,
            mountFollowMouseModal: action,
            unmountFollowMouseModal: action,

            //Debug
            debug: observable,
            toggleDebug: action,

            aggiornamentoGenerico: observable,
            triggerAggiornamentoGenerico: action
        })
    }

    cambiaLingua = (lingua: string) => {
        this.lingua = lingua
    }

    //Trigger dell'aggiornamento generico
    triggerAggiornamentoGenerico = () => {
        this.aggiornamentoGenerico = !this.aggiornamentoGenerico
    }

    //Header modal
    toggleHeaderModal = () => {
        //Il timeout permette di far comparire il menu dopo che l'utente ha cliccato
        setTimeout(() => {
            runInAction(() => this.headerModal = !this.headerModal)
        }, 10)
    }

    //Aside modal
    mountAsideModal = (component: JSX.Element, bloccaUi?: boolean) => {
        if (this.asideModal) {
            this.unmountConfermaChiusuraAsideModal(component);
        } else {
            this.asideModal = true

            this.asideModalComponent = component

            if (bloccaUi) { this.bloccaUi = true }
        }
    }

    unmountConfermaChiusuraAsideModal = (asideModalComponentSuccessivo?: JSX.Element) => {
        this.ConfermaChiusuraAsideModal = !this.ConfermaChiusuraAsideModal

        if (asideModalComponentSuccessivo) {
            this.asideModalComponentSuccessivo = asideModalComponentSuccessivo
        }
    }

    unmountAsideModal = () => {
        this.asideModal = false
        this.asideModalComponent = false
        this.bloccaUi = false
        this.ConfermaChiusuraAsideModal = !this.ConfermaChiusuraAsideModal

        if (this.asideModalComponentSuccessivo) {
            this.mountAsideModal(this.asideModalComponentSuccessivo as JSX.Element)
            this.asideModalComponentSuccessivo = false
        }
    }

    //Gib Screen modal
    mountBigScreenModal = (component: JSX.Element) => {
        if (this.bigScreenModal) {
            this.unmountConfermaChiusuraBigScreenModal();
        } else {
            this.bigScreenModal = true

            this.bigScreenModalComponent = component
        }
    }

    unmountConfermaChiusuraBigScreenModal = () => {
        this.ConfermaChiusuraBigScreenModal = !this.ConfermaChiusuraBigScreenModal
    }

    unmountBigScreenModal = () => {
        this.bigScreenModal = false
        this.bigScreenModalComponent = false
        this.bloccaUi = false
        this.ConfermaChiusuraBigScreenModal = !this.ConfermaChiusuraBigScreenModal
    }

    //Log Screen modal
    mountLogScreenModal = (component: JSX.Element) => {
        if (this.logScreenModal) {
            this.unmountConfermaChiusuraLogScreenModal();
        } else {
            this.logScreenModal = true

            this.logScreenModalComponent = component
        }
    }

    unmountConfermaChiusuraLogScreenModal = () => {
        this.ConfermaChiusuraLogScreenModal = !this.ConfermaChiusuraLogScreenModal
    }

    unmountLogScreenModal = () => {
        this.logScreenModal = false
        this.logScreenModalComponent = false
        this.bloccaUi = false
        this.ConfermaChiusuraLogScreenModal = !this.ConfermaChiusuraLogScreenModal
    }

    //Dialogue
    mountDialogue = (component: JSX.Element) => {
        this.dialogue = true

        this.dialogueComponent = component
    }

    unmountDialogue = () => {
        this.dialogue = false

        this.dialogueComponent = false
    }

    //Main nav mobile
    toggleMainNavMobile = () => {
        this.mainNavMobile = !this.mainNavMobile
    }

    //Main nav reduce
    toggleMainNavFull = () => {
        this.mainNavFull = !this.mainNavFull
    }

    //Search bar mobile
    toggleSearchBarMobile = () => {
        this.searchBarMobile = !this.searchBarMobile
        this.ricerca = ''
        this.ricercaUtenti = ''
    }

    resettaRicerca = () => {
        this.ricerca = ''
        this.ricercaUtenti = ''
    }

    //Follow mouse
    mountFollowMouse = (component: JSX.Element) => {

        this.followMouse = true

        this.followMouseComponent = component

    }

    unmountFollowMouse = () => {

        this.followMouse = false

        this.followMouseComponent = false


    }
    //Follow mouse
    mountFollowMouseModal = (component: JSX.Element) => {

        this.followMouseModal = true

        this.followMouseModalComponent = component

    }

    unmountFollowMouseModal = () => {

        this.followMouseModal = false

        this.followMouseModalComponent = false

    }

    //Main nav mobile
    toggleDebug = () => {
        if (this.debug) {

            localStorage.removeItem('debug')
            this.debug = false

        } else {

            localStorage.setItem('debug', 'true')
            this.debug = true

        }
    }

    mountToast = (toast: IToast) => {

        this.toast = toast

        setTimeout(() => runInAction(() => this.toast = null), 2000)

    }
}